:root {
  --primary: #b97545;
  --primary-light: #c38960;
  --secondary: #647033;
  --secondary-light: #839343;
}


/* TAILWIND CSS BREAKPOINTS*/
/* Small (sm) */
@media (min-width: 640px) { 
  #about-section {
    background-image: url('../assets/images/hero-bg-sm.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
 }

/* Medium (md) */
@media (min-width: 768px) { 
  .md\:text-primary {
    color: var(--primary);
  }
}

/* Large (lg) */
@media (min-width: 1024px) { 
  #hero-section {
    min-height: 60vh;
  }
  #about-section {
    background-image: url('../assets/images/hero-bg-lg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

/* Extra Large (xl) */
@media (min-width: 1280px) { /* ... */ }

#about-container {
  background-color: rgba(37, 46, 64, .8);
}

.text-primary {
  color: var(--primary);
}
.text-secondary {
  color: var(--secondary)
}
.bg-primary {
  background-color: var(--primary);
}
.hover\:bg-primary-light:hover {
  background-color: var(--primary-light);
}
.bg-secondary {
  background-color: var(--secondary);
}
.bg-secondary-light {
  background-color: var(--secondary-light);
}

.state-field {
  display: none;
}